.ia-icon-button {
  height: 30px !important;
  width: 30px !important;
  color: #394960 !important;
  border: 0.8px solid #758490 !important;
}

.ia-icon-button-custom {
  height: 37px; 
  width: 36px;
  color: #394960;
  border: 0.8px solid #0055AF;
}


.ia-iconbtn-size{
  height: 30px !important;
}
.ia-iconbtn-size .button-children-container{
  height: 16px !important;
  line-height: 15px!important;
}
.ia-icon-button .button-icon-container {
  width: 16px !important;
  height: 16px !important;
}

.ia-icon-button .button-icon-container .MuiSvgIcon-root path {
  color: #394960 !important;
  fill: #394960 !important;
}
.ia-icon-button .button-icon-container svg {
  width: 16px !important;
  height: 16px !important;
}
.material-symbols-outlined {
  color: #394960 !important;
  width: 16px !important;
  height: 16px !important;
  font-size: 16px!important;
}

button.monday-smart-btn.btn-without-label path {
  fill: #394960 !important;
}

button.btn-without-label:disabled {
  border-color: #75849040 !important;
  background: #fff;
}

button.ia-icon-button.btn-without-label:disabled path {
  fill: #39496050 !important;
}

.custom-action-button{
  padding: 4.5px 16px;
  height: 30px;
  gap: 6px;
}

.custom-action-button > span{
  line-height: 21px;
  font-weight: 400;
}


.custom-action-icon-button{
  height: 36px;
  width: 36px;
  border-radius: 100%;
}
.custom-action-icon-button:hover{
  background-color: #EBF4FE;
}

.custom-action-icon-button:hover > span > span,       /* for google icon */
.custom-action-icon-button:hover .button-icon-container .MuiSvgIcon-root path,
.custom-action-icon-button:hover > span > svg > path {
  color: #0055AF !important;
  fill: #0055AF;
}

.custom-action-icon-button >span{
  height: 24px ;
  width: 24px ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-action-icon-button >span> span{
  height: 24px !important;
  width: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
}
/* for MUI icon */
.custom-action-icon-button .button-icon-container .MuiSvgIcon-root path{
  fill: #394960;
}

/* for direct svg */
.custom-action-icon-button > span >svg>path{
  fill: #394960;
}

.custom-action-icon-button > span > svg {
  height: 20px !important;
  width: 20px !important;
}

.monday-smart .button-icon-container .MuiSvgIcon-root path{
  fill: #808994 ;
} 


